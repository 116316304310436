const slideItems = [...document.querySelectorAll('#investment-solutions .slide-item')];

let initialScrollPositionY = 0;
let scrollOptions;

slideItems.forEach(el => { 
  el.addEventListener('click', e => {
    let clickedEl = e.currentTarget;
    
    initialScrollPositionY = initialScrollPositionY === 0 ? window.scrollY : initialScrollPositionY;
    scrollOptions = {
      left: 0,
      top: initialScrollPositionY,
      behavior: 'smooth'
    }

    if (e.currentTarget.classList.contains('mobActive')) {
      console.log('target contains mobActive', initialScrollPositionY);
      document.querySelector('.mobActive').classList.remove('mobActive');
      window.scrollTo(scrollOptions);
      initialScrollPositionY = 0;
    } else {
      console.log('mobActive not found', initialScrollPositionY);
      console.log('adding mobActive class to current element in else');

      if (document.querySelector('.mobActive')) {
        document.querySelector('.mobActive').classList.remove('mobActive');
        window.scrollTo(scrollOptions);
        initialScrollPositionY = 0;
      }
      clickedEl.classList.add('mobActive');
      clickedEl.scrollIntoView({ behavior: 'smooth'});
    }
  }, false);
})