class Header {

	constructor(element) {
		this.el = element;
		this.scrollDirection = null;
		this.scrollY = 0;
		this.timer = null;
		this.showNavtimer = null;
		this.animatingHeaderMobile = false;
		this.isSmall = false;
		this.mouseenter = false;
		this.showDesktopNav = (window.innerWidth > 1024)? true : false;
		this.showTabletNav = (window.innerWidth < 1025 && window.innerWidth > 767)? true : false;
		this.showMobileNav = (window.innerWidth < 768)? true : false;

		this.onScroll = this.onScroll.bind(this);
		this.onResize = this.onResize.bind(this);

		this.initialize();
	}

	initialize() {
		
		window.addEventListener('scroll', this.onScroll);
		window.addEventListener('resize', this.onResize);
		this.el.addEventListener('mouseenter', () => { this.mouseenter = true; });
		this.el.addEventListener('mouseleave', () => { this.mouseenter = false; this.delayClose(); });
		this.onScroll();
	}

	onScroll(e) {
		
		const scrollY = document.documentElement.scrollTop || document.body.scrollTop;

		if (scrollY != this.scrollY) {
			this.scrollDirection = scrollY > this.scrollY ? 'bottom' : 'top';
			this.scrollY = scrollY;

			this.update();
			this.delayClose();
		}
	}

	onResize(e) {
		this.showDesktopNav = (window.innerWidth > 1024)? true : false;
		this.showTabletNav = (window.innerWidth < 1025 && window.innerWidth > 767)? true : false;
		this.showMobileNav = (window.innerWidth < 768)? true : false;
		if( this.showMobileNav) {
			if(this.el.classList.contains('small')){
				this.el.classList.remove('small');
				this.el.classList.remove('show');
			}
		} else if (this.scrollY > 180 ) {
			this.el.classList.add('show');
		}
	}

	delayClose() {
		clearTimeout(this.timer);
		this.timer = setTimeout(() => {
			if (this.isSmall && !this.mouseenter && this.showMobileNav) {
				this.el.classList.remove('show');
			}
		}, 2000);
	}

	update() {
		
		if( !this.showMobileNav ){
			if (this.scrollY > 180 ) {
				if(!this.isSmall){
					if(this.showDesktopNav){
						clearTimeout(this.showNavtimer);
						this.showNavtimer = setTimeout(() => {
							this.el.classList.add('show');
						}, 100);
					} else {
						this.el.classList.add('show');
					}
				}
				this.isSmall = true;
				this.el.classList.add('small');
				this.el.parentNode.classList.add('marge-top');
			} else {
				this.el.classList.remove('small');
				this.el.classList.remove('show');
				this.el.parentNode.classList.remove('marge-top');
				this.isSmall = false;
			}
		} else{
			
			this.el.parentNode.classList.remove('marge-top');
			if (this.scrollY > 100 && this.scrollDirection === 'bottom') {
				this.el.classList.remove('show');
			}
			if (this.scrollY > 100 ) {
				this.el.classList.add('small');

				this.isSmall = true;
				if (this.scrollDirection === 'top') {
					this.el.classList.add('show');
				}
			} else {
				this.el.classList.remove('small');
				this.el.classList.remove('show');
				this.isSmall = false;
			}
		}
	}
}

export default Header;