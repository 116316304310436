import smoothscroll from 'smoothscroll-polyfill';
smoothscroll.polyfill();

const burgerBtn = document.getElementById('burgerBtn');
const navMobile = document.getElementById('navMobile');
const subNavMobileItems = [...document.querySelectorAll('#navMobile .nav-sub a')];
const navLinks = [...document.querySelectorAll('.nav-list a[data-tag]')];
const mainNavLinks = [...document.querySelectorAll('.nav-main a')];
const anchorSections = [...document.querySelectorAll('.anchorSection')];
const subNav = [...document.querySelectorAll('.nav-sub')];
const showMobileNav = (window.innerWidth < 1025)? true : false;
const navMobileEl = document.querySelector('.nav-mobile');
const subnavDesktopEl = document.querySelector('.subNavDesktop');
let activeSectionEl = null;
let sectionSizePos = [];
let scrollpos = (document.documentElement && document.documentElement.scrollTop) || document.body.scrollTop;
let screenHeight = window.innerHeight;

if( !showMobileNav ){
  mainNavLinks.forEach(el => {
    el.addEventListener('mouseover', e => {

      if(e.currentTarget.className != 'activeLink'){

        if(document.querySelector('.nav-sub-'+e.currentTarget.dataset.link)){
          console.log( document.querySelector('.nav-sub-'+e.currentTarget.dataset.link) );
          document.querySelector('.nav-sub-'+e.currentTarget.dataset.link).classList.add('show');
        }
        else if( !document.querySelector('.home') ){
          document.querySelector('.subNavDesktop').classList.add('no-sub');
        }
      }
    }, false);

    el.addEventListener('mouseleave', e => {
      document.querySelector('.subNavDesktop').classList.remove('no-sub');
      if(document.querySelector('.nav-sub-'+e.target.dataset.link)){
        setTimeout( () => {
          if( !document.querySelector('.nav-sub-'+e.target.dataset.link).classList.contains('mouse-over') ){
            document.querySelector('.nav-sub-'+e.target.dataset.link).classList.remove('show');
          }
        }, 100);
      }
    }, false);
  });
  subNav.forEach(el => {
    el.addEventListener('mouseover', e => {
      if(!e.currentTarget.classList.contains('activeSubNav')){
        e.currentTarget.classList.add('mouse-over');
      }
    }, false);
    el.addEventListener('mouseleave', e => {
      if(!e.currentTarget.classList.contains('activeSubNav')){
        e.currentTarget.classList.remove('mouse-over');
        e.currentTarget.classList.remove('show');
      }
    }, false);
  });
}

document.querySelectorAll('.subNavDesktop .nav-sub.activeSubNav a').forEach(el => {
  el.addEventListener('click', e => {
    e.preventDefault();

    let target = e.currentTarget;
    if( target.classList.contains('activeLink') ){ return false; }
    var target_id = target.getAttribute('id').split('_')[0];
    let targetOffsetTop = document.getElementById(target_id).offsetTop;
    console.log(target, targetOffsetTop)

    // document.getElementById(target_id).scrollIntoView({
    //   behavior: 'smooth',
    //   align: "top",
    //   top: 100,
    //   block: 'start'
    // });
    window.scrollTo({behavior: 'smooth', top: targetOffsetTop + 165 , left: 0});
  }, false);
});

setTimeout( () => {
  if(document.location.hash){
    var target = document.location.hash.split('#')[1];
    if(target != "#" && target.length > 1){
      let targetOffsetTop = document.getElementById(target).offsetTop;
      window.scrollTo({behavior: 'smooth', top: targetOffsetTop + 165 , left: 0});
    }
  }
}, 500);

window.onload = function(){
  getSectionDetails();
  setNavLinkActive();
}
window.onresize = function(){
  scrollpos = (document.documentElement && document.documentElement.scrollTop) || document.body.scrollTop;
  screenHeight = window.innerHeight;
  getSectionDetails();
}

function getSectionDetails(){
  const sections = [...document.querySelectorAll('section.anchorSection')];
  sections.forEach(el => {
    var sectionDetails = {
      section: el,
      top: el.getBoundingClientRect().top + scrollpos,
      bottom: el.getBoundingClientRect().top + scrollpos + el.offsetHeight,
      height: el.offsetHeight
    }
    sectionSizePos.push(sectionDetails);
  })
}
window.onscroll = function () {
  scrollpos = (document.documentElement && document.documentElement.scrollTop) || document.body.scrollTop;
  setNavLinkActive();
};

function setNavLinkActive(){
  sectionSizePos.forEach(sectionInfo => {
    if(scrollpos > 100){
      if( (sectionInfo.top+120) < (scrollpos+screenHeight) && (sectionInfo.bottom) > (scrollpos+screenHeight-120)){ //120 padding-top
        addActiveNavLink(sectionInfo.section.id);
      }
      else{
        if( scrollpos+screenHeight > sectionSizePos[sectionSizePos.length -1].bottom ){
          let bottomSectionId = sectionSizePos[sectionSizePos.length -1].section.id;
          addActiveNavLink(bottomSectionId);
        } else {
          removeActiveNavLink(sectionInfo.section.id);
        }
      }
    }
    else{
      removeActiveNavLink(sectionInfo.section.id);
    }
  });
}

function addActiveNavLink(target){
  if( subnavDesktopEl.querySelector(`#${target}_link`) !== null){
    subnavDesktopEl.querySelector(`#${target}_link`).classList.add('activeLink');
  }
  if( navMobileEl.querySelector(`.${target}_linkmobile`) !== null){
    navMobileEl.querySelector(`.${target}_linkmobile`).classList.add('activeLink');
  }
}
function removeActiveNavLink(target){
  if (document.getElementById(target+'_link') !== null ) {
    document.getElementById(target+'_link').classList.remove('activeLink');
  }
  if( navMobileEl.querySelector(`.${target}_linkmobile`) !== null){
    navMobileEl.querySelector(`.${target}_linkmobile`).classList.remove('activeLink');
  }
}

burgerBtn.addEventListener('click', e => {
  //console.log('clicked on burger', e.currentTarget)
  if( !burgerBtn.classList.contains('active')){
    burgerBtn.classList.add('active');
    navMobile.classList.toggle('opened');
    document.body.classList.toggle('mob-nav-opened');
    navMobile.querySelector('.nav-mobile_main').scrollTo(0, 0);
    navMobile.addEventListener('touchmove', preventBGScroll, false);
    // document.getElementById('app').classList.add('formOpen');
  } else {
    burgerBtn.classList.remove('active');
    navMobile.classList.toggle('opened');
    document.body.classList.toggle('mob-nav-opened');
    navMobile.querySelector('.nav-mobile_main').scrollTo(0, 0);
    navMobile.removeEventListener('touchmove', preventBGScroll, false);
    // document.getElementById('app').classList.remove('formOpen');
  }
}, false);

subNavMobileItems.forEach( el => {
  el.addEventListener('click', e => {
    e.preventDefault();
    burgerBtn.classList.remove('active');
    navMobile.classList.remove('opened');
    document.body.classList.remove('mob-nav-opened');
    var target = e.currentTarget.getAttribute('href').split('#')[1];
    document.getElementById(target).scrollIntoView({behavior: 'smooth'});

    navMobile.removeEventListener('touchmove', preventBGScroll, false);
  },false);
});

navMobile.addEventListener('click', e => {
  if (e.target.classList.contains('container')) {
    if(navMobile.classList.contains('opened')){
      burgerBtn.classList.remove('active');
      navMobile.classList.remove('opened');
      document.body.classList.remove('mob-nav-opened');
    }
  }
});


function preventBGScroll(e){
  e.preventDefault();
  // console.log(' touchmove event listener', e.currentTarget);
}


/** click tags **/
document.addEventListener('DOMContentLoaded', () => {
  const isLocal = window.location.hostname.indexOf('local') > -1;

  navLinks.forEach(el => {
    el.addEventListener('click', e => {
      if (ATTag) {
        e.preventDefault();

        if(e.target.dataset.link && !isLocal) {
          e.currentTarget.href = e.target.dataset.link;
        }

        ATTag.click.send({
          elem: e.currentTarget,
          name: e.currentTarget.dataset.tag,
          level2: '',
          type: 'action',
        });

      } else if(e.target.dataset.link && !isLocal) {
        e.currentTarget.href = e.target.dataset.link;
      }

    }, false);
  });
});