import { EventEmitter } from 'events';

class Slider extends EventEmitter {

  constructor(el) {
    super();

    this.slides = [...el.querySelectorAll('[data-slide]')];
    this.links = [...el.querySelectorAll('[data-target]')];
    this.arrows = [...el.querySelectorAll('[data-arrow]')];
    this.slideID = 1;

    this.onArrowClick = this.onArrowClick.bind(this);
    this.onLinksClick = this.onLinksClick.bind(this);

    this.arrows.forEach(el => {
      el.addEventListener('click', this.onArrowClick, false);
    });

    this.links.forEach(el => {
      el.addEventListener('click', this.onLinksClick, false);
    });

    this.initialize();
  }

  initialize() {
    setTimeout(() => { this.update(); }, 0);
  }

  update(id=this.slideID) {
    id -= 1;

    this.slides.forEach((el, index) => {
      if (index === id) {
        el.classList.add('active');
      } else {
        el.classList.remove('active');
      }
    });
    this.links.forEach((el, index) => {
      if (index === id) {
        el.classList.add('active');
      } else {
        el.classList.remove('active');
      }
    });

    if (id === 0) {
      this.arrows[0].classList.add('hide');
    } else {
      this.arrows[0].classList.remove('hide');
    }

    if (id === this.slides.length - 1) {
      this.arrows[1].classList.add('hide');
    } else {
      this.arrows[1].classList.remove('hide');
    }

    this.slideID = id;
  }

  onArrowClick(e) {
    e.preventDefault();

    const dir = e.currentTarget.dataset.arrow;
    let targetID = dir === 'prev' ? this.slideID - 1 : this.slideID + 1;
    targetID = Math.max(0, Math.min(targetID, this.slides.length - 1));

    this.emit('newSlide', targetID);
  }

  onLinksClick(e) {
    e.preventDefault();

    const targetID = Number(e.currentTarget.dataset.target) - 1;

    this.emit('newSlide', targetID);
  }
}

export default Slider;