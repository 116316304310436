import 'babel-polyfill';
import objectFitPolyfill from 'objectFitPolyfill';
import Stickyfill from 'stickyfilljs';

import Slider from './Slider.js';
import Subnav from './Subnav.js';
import Header from './Header.js';

import './solutions.js';
import './navMenu.js';
// import './form.js';

if(document.location.pathname.length > 1){
  var elements = document.querySelectorAll('.sticky');
  Stickyfill.add(elements);
}

const route = () => {

  // Routing
  switch (document.documentElement.classList[0]) {
  case 'home':
    startHome();
    break;

  case 'entrepreneurs':
    startEntrepreneurs();
    break;

  case 'accompagnement':
    startAccompagnement();
    break;

  case 'solutions':
    startSolutions();
    break;
  }
};

if(msieversion()){
  document.querySelector('body').classList.add('ie');
}

const startHome = () => {
  // console.log('[INFO] Homepage');

  const headerEl = document.querySelector('.header-nav');
  const header = new Header(headerEl);

};

const startEntrepreneurs = () => {
  // console.log('[INFO] EntrepreneursPage');
  const headerEl = document.querySelector('.header-nav');
  const header = new Header(headerEl);
};

const startAccompagnement = () => {
  // console.log('[INFO] Accompagnement');

  const headerEl = document.querySelector('.header-nav');
  const subNavEl = [...headerEl.querySelectorAll('.nav-sub a')];
  const sliderEl = document.querySelector('#slider');

  const header = new Header(headerEl);
  const slider = new Slider(sliderEl);

  slider.on('newSlide', id => {
    slider.update(id + 1);
  });
};

const startSolutions = () => {
  // console.log('[INFO] Solutions');

  const headerEl = document.querySelector('.header-nav');
  const subNavEl = [...headerEl.querySelectorAll('.nav-sub a')];
  const sliderEl = document.querySelector('#slider');
  const slidesEl =  sliderEl.querySelectorAll('.slide-item');

  const header = new Header(headerEl);
  const slider = new Slider(sliderEl);
  let sliderOffsetTop = sliderEl.offsetTop;

  slider.on('newSlide', id => {
    slider.update(id + 1);
    window.scrollTo({behavior: 'smooth', top:sliderOffsetTop, left: 0});
  });
};



const cookieConsent = () => {
  let acceptCookie = false;
  const cookieBannerEl = document.querySelector('.cookie-banner');
  const closeCookieEl = cookieBannerEl.querySelector('#cookie-close');

  try {
    acceptCookie = Boolean(localStorage.getItem('accept-cookie'));
  } catch(err) {}

  if (!acceptCookie) {
    cookieBannerEl.classList.remove('hide');
  }

  closeCookieEl.addEventListener('click', (e) => {
    e.preventDefault();
    cookieBannerEl.classList.add('hide');

    try {
      localStorage.setItem('accept-cookie', 'true');
    } catch (err) {
      // TODO
    }
  });
};

const onScroll= (intro_visu) => {
  if( document.querySelector('body').classList.contains('ie') ){
    let scrollpos = (document.documentElement && document.documentElement.scrollTop) || document.body.scrollTop;
    if(scrollpos > 100 && scrollpos < 400){
      intro_visu.classList.add('show-borders');
    }
  } else {
    if(!intro_visu.classList.contains('show-borders')){
      (async () => {
        const visible = await isVisible(intro_visu);
        if(visible){
          intro_visu.classList.add('show-borders');
        }
      })();
    }
  }
  //Correct sticky on IE
  // if(msieversion()){
  //   correctIESticky();
  // }
};

const correctIESticky = () => {
  var stickies = document.querySelectorAll('.sticky');
  stickies.forEach(sticky => {
    var positionValue = getComputedStyle(sticky).position;
    var topValue = (getComputedStyle(sticky).top.indexOf('px') > 0)? parseInt(getComputedStyle(sticky).top.split('px')[0], 10) : "";
    console.log(positionValue, topValue);
    var newTopValue = "0px";
    if(positionValue === "absolute"){
      newTopValue = '166px';
      if(sticky.parentNode.classList.contains('container')) {
        newTopValue = "0px";
      }
    }
    else{
      if(topValue > 166){
        newTopValue = '166px';
      }
    }
    
    sticky.style.top = newTopValue;
  });
}


const isVisible= (domElement) => {
  return new Promise(resolve => {
    const o = new IntersectionObserver(([entry]) => {
      resolve(entry.intersectionRatio === 1);
      o.disconnect();
    });
    o.observe(domElement);
  });
};

const startApp = () => {
  cookieConsent();
  route();
  let intro_visu = document.querySelector('.intro-visual');
  if(intro_visu){
    window.addEventListener('scroll', onScroll.bind(null, intro_visu), false);
  }
};

function msieversion() {

  var ua = window.navigator.userAgent;
  var msie = ua.indexOf("MSIE ");

  if (msie > 0 || !!navigator.userAgent.match(/Trident.*rv\:11\./))  // If Internet Explorer, return version number
  {
    var msie = true;
  }
  else  // If another browser, return 0
  {
    msie = false;
  }

  return msie;
}

document.addEventListener('DOMContentLoaded', startApp);